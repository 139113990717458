import React from 'react';
import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';

import styles from './styles.module.css';

import helper from './helper';

class Image extends React.Component {

  constructImage = (imageId) => {

    let image;

    if (imageId && imageId.length > 0) {

      image = helper.getImageById(imageId, this.props.images);

    }

    const url = this.props.url || this.props.CDNLink;

    if (!image && url) {

      image = {
        path: url,
        alt: this.props.alt || 'static',
      };

    }

    if (!image) {

      // TODO very good place for "broken image" image here
      return (
        <img
          src="/.jpg"
          alt="broken"
        />
      );

    }

    return image;

  };

  render() {

    let image = this.constructImage(this.props.id);

    if (!image && this.props.url) {

      image = {
        path: this.props.url,
        alt: this.props.alt || 'static',
      };

    }

    if (!image) {

      // TODO very good place for "broken image" image here
      return (
        <img
          src="/.jpg"
          alt="broken"
        />
      );

    }

    const style = {}; // to avoid empty props in the html

    if (this.props.position) {

      style.objectPosition = this.props.position;

    }

    if (this.props.opacity) {

      style.opacity = this.props.opacity;

    }

    if (this.props.size) {

      style.objectFit = this.props.size;

    }

    const srcSet = helper.getSrcSet(image);
    let frmt;
    if (image && image.sizes && image.sizes.includes('me')) frmt = 'me';
    const imgSrc = helper.getImgSrc(image.path, frmt);
    let className = '';

    if(this.props.imageClass && this.props.imageClass.length > 0) {

      className = styles[this.props.imageClass];

    }

    if (srcSet && srcSet.length > 0) {

      return (
        <img
          src={imgSrc}
          srcSet={srcSet}
          sizes={this.props.sizes || '100vw'}
          alt={this.props.alt || image.alt}
          data-index={this.props.dataIndex}
          data-disableicon={this.props.disableIcon}
          data-mediatype={this.props.mediaType}
          data-componentindex={this.props.componentIndex}
          data-elementindex={this.props.elementIndex}
          className={className}
          onClick={this.props.onClick}
          style={style}
          data-imageid={this.props.id}
        />
      );

    }

    return (
      <img
        src={imgSrc}
        alt={this.props.alt || image.alt}
        data-index={this.props.dataIndex}
        data-disableicon={this.props.disableIcon}
        data-mediatype={this.props.mediaType}
        data-componentindex={this.props.componentIndex}
        data-elementindex={this.props.elementIndex}
        className={className}
        onClick={this.props.onClick}
        style={style}
        data-imageid={this.props.id}
      />
    );

  }

}

Image.propTypes = {
  id: PropTypes.string,
  size: PropTypes.string,
  sizes: PropTypes.string,
  imageClass: PropTypes.string,
  imageStyle: PropTypes.string,
  dataIndex: PropTypes.number,
  mediaType: PropTypes.string,
  disableIcon: PropTypes.bool,
  position: PropTypes.string,
  opacity: PropTypes.number,
  url: PropTypes.string,
  elementIndex: PropTypes.number,
  componentIndex: PropTypes.number,
  alt: PropTypes.string,
  onClick: PropTypes.func,
};

export default CSSModules(Image, styles, { allowMultiple: true });
