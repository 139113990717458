import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';

import NavigationLinks from './NavigationLinks';
import Image from '../Image';
import styles from './styles.module.css';
import { formColor } from '../../helper';

class NavigationBar extends React.Component {

  constructor(props) {

    super(props);

    const { logo } = props.pageContext;
    const { sitename } = props.pageContext;
    const { themeData } = props.pageContext;
    const section = props.pageContext.navigation;

    const links = [];

    const { sections } = props.pageContext.page;

    const newPages = this.props.pageContext.navLinks;

    if (section.data[0].link_to_pages) {

      newPages.forEach((page, index) => {

        if (page.show_in_main_navigation) {

          links.push({ item: page, index });

        }

        return null;

      });

    } else if (section.data[0].link_to_sections && props.pageContext.article !== true) {

      sections.forEach((sec, index) => {

        if (sec.type !== 'NAVIGATION' && sec.show_in_main_navigation) {

          links.push({ item: sec, index });

        }

        return null;

      });

    }

    const burgerBreak = Number(section.data[0].burger) || 5;

    this.state = {
      sections,
      links: links,
      burgerBreak,
      id: section._id,
      recStyles: section.data[0].styles,
      link_to_pages: section.data[0].link_to_pages,
      link_to_sections: section.data[0].link_to_sections,
      logo,
      sitename,
      themeData,
      overlay: section.data[0].overlay,
      full: section.data[0].full,
      fixed_top: section.data[0].fixed_top,
      layout: section.data[0].layout,
      title: section.title,
      type: section.type,
      matches: null,
      isScrolling: false,
    };

    this.updateMatches = this.updateMatches.bind(this);
    this.handleScroll = this.handleScroll.bind(this);

  }

  componentDidMount() {

    const breakpoint = '769px';
    this.mediaQueryList = window.matchMedia(`(min-width: ${breakpoint})`);
    this.mediaQueryList.addListener(this.updateMatches);

    this.setState({
      matches: window.matchMedia(`(min-width: ${breakpoint})`).matches,
    });

  }

  componentWillUnmount() {

    if (this.mediaQueryList) {

      this.mediaQueryList.removeListener(this.updateMatches);

    }

  }

  updateMatches() {

    this.setState({
      matches: this.mediaQueryList.matches,
    });

  }

  handleScroll(isScrolling) {

    if (this.state.fixed_top && isScrolling !== this.state.isScrolling) {

      this.setState({
        isScrolling,
      });

      this.props.update('isScrolling', isScrolling);

    }

  }

  render() {

    const titleStyle = {
      fontFamily: this.state.recStyles.font.fontFamily !== '' ? this.state.recStyles.font.fontFamily : this.state.themeData.typography.default.name,
      fontSize: this.state.recStyles.font.fontSize,
      lineHeight: 1.5,
      color: this.state.recStyles.font.titleColor !== '' ? this.state.recStyles.font.titleColor : this.state.themeData.colors[3],
      fontWeight: '700',
      textTransform: 'initial',
    };

    let style;

    if (this.state.recStyles) {

      let { backgroundColor } = this.state.recStyles;
      if (
        this.state.recStyles.backgroundColor.solid === ''
        && this.state.recStyles.backgroundColor.gradient.from === ''
      ) {

        backgroundColor = { solid: '#FFFFFF' };

      }

      const opacity = this.state.isScrolling === false
        ? this.state.recStyles.backgroundColor.opacity : 1;
      const bgColor = formColor(
        backgroundColor,
        false,
        opacity,
      );

      let sColor;
      if (this.state.recStyles.shadow.active) {

        let solid = this.state.recStyles.shadow.color;
        if (this.state.recStyles.shadow.color === '') {

          [, , solid] = this.state.themeData.colors;

        }

        sColor = formColor(
          { solid },
          false,
          this.state.recStyles.shadow.opacity,
        );

      }

      const x = this.state.recStyles.shadow.active ? this.state.recStyles.shadow.x : undefined;
      const y = this.state.recStyles.shadow.active ? this.state.recStyles.shadow.y : undefined;
      const blur = this.state.recStyles.shadow.active ?
        this.state.recStyles.shadow.blur : undefined;
      const spread = this.state.recStyles.shadow.active ?
        this.state.recStyles.shadow.spread : undefined;
      const boxShadow = (this.state.recStyles.shadow.active && this.state.recStyles.shadow.color !== '') ?
        `${x}px ${y}px ${blur}px ${spread}px ${sColor.backgroundColor}`
        :
        undefined;
      const WebkitFilter = this.state.recStyles.backgroundBlur.active ?
        `blur(${this.state.recStyles.backgroundBlur.blur}px)` : 'none';
      const MozFilter = this.state.recStyles.backgroundBlur.active ?
        `blur(${this.state.recStyles.backgroundBlur.blur}px)` : 'none';
      const OFilter = this.state.recStyles.backgroundBlur.active ?
        `blur(${this.state.recStyles.backgroundBlur.blur}px)` : 'none';
      const msFilter = this.state.recStyles.backgroundBlur.active ?
        `blur(${this.state.recStyles.backgroundBlur.blur}px)` : 'none';
      const filter = this.state.recStyles.backgroundBlur.active ?
        `blur(${this.state.recStyles.backgroundBlur.blur}px)` : 'none';

      style = {
        boxShadow,
        WebkitFilter,
        MozFilter,
        OFilter,
        msFilter,
        filter,
      };

      if (this.state.recStyles.backgroundColor.gradient.from !== '' && this.state.recStyles.backgroundColor.active) {

        style.background = bgColor.background;

      } else if (this.state.recStyles.backgroundColor.active) {

        style.backgroundColor = bgColor.backgroundColor;

      }

      let solid = '#000000';
      if (this.state.recStyles.font.fontColor.color !== '') {

        solid = this.state.recStyles.font.fontColor.color;

      }

      const fontColor = formColor(
        {
          solid,
        },
        undefined,
        this.state.recStyles.font.fontColor.opacity,
      );

      style.color = fontColor.backgroundColor;

    }

    const burgerColor = formColor(
      { solid: this.state.recStyles.burgerColor.color },
      false,
      this.state.recStyles.burgerColor.opacity,
    );

    let navbar;
    let logo;
    let menu;
    let noLinks;
    if (this.state.layout === 'left') {

      navbar = 'navbar';
      logo = 'logoLeft';
      menu = 'menuRight';

    } else if (this.state.layout === 'center') {

      logo = 'logoCenter';
      navbar = 'navbarCenter';
      menu = 'menuCenter';

    } else if (this.state.layout === 'right') {

      navbar = 'navbarReverse';
      logo = 'logoRight';
      menu = 'menuLeft';

    } else if (this.state.layout === 'divided') {

      if (
        this.state.links.length > this.state.burgerBreak ||
        (!this.state.matches && this.state.links.length > 0)
      ) {

        navbar = 'navbar';
        menu = 'menuRight';
        logo = 'logoDividedBurger';

      } else {

        navbar = 'navbarDivided';

        if (this.state.links.length === 0) {

          noLinks = 'navbarDividedNoLinks';

        }

        menu = 'menuDivided';
        logo = 'logoDivided';

      }

    }

    let navStyle;
    if (
      this.state.fixed_top === false &&
      this.state.overlay === false
    ) {

      navStyle = 'sectionNavbar';

    } else if (
      this.state.fixed_top === true &&
      this.state.overlay === false
    ) {

      navStyle = 'sectionNavbarTop';

    } else if (
      this.state.fixed_top === true &&
      this.state.overlay === true
    ) {

      navStyle = 'sectionNavbarTopOverlay';

    } else if (
      this.state.fixed_top === false &&
      this.state.overlay === true
    ) {

      navStyle = 'sectionNavbarOverlay';

    }

    const navWrapper = this.state.full === true ? 'navbarFull' : 'navbarPartial';
    const layout = this.state.layout.charAt(0).toUpperCase() + this.state.layout.slice(1);
    const target = '/';
    const shrink = this.state.isScrolling && this.state.logo && this.state.logo.active;
    const linkStyle = shrink ? { display: 'block', lineHeight: 0 } : undefined;

    const lg = (
      <div className={styles[logo]}>
        <Link to={target} className={styles.linkStyle} style={linkStyle}>
          {
            !this.state.logo || (this.state.logo && this.state.logo.active !== true)
              ? (
                <span style={titleStyle}>
                  {this.state.sitename}
                </span>
              )
              : (
                <Image
                  id={this.state.logo.id}
                  url={this.state.logo.src}
                  CDNLink={this.state.logo.CDNLink}
                  alt="Logo"
                  imageClass={`navbarLogo${shrink ? 'Scrolling' : ''}`}
                  images={this.props.pageContext.images}
                />
              )
          }
        </Link>
      </div>
    );

    // Domain and preview params from FE not needed
    const nav = (
      <NavigationLinks
        sections={this.state.sections}
        links={this.state.links}
        burgerBreak={this.state.burgerBreak}
        burgerColor={burgerColor}
        layout={layout}
        menuStyle={menu}
        lg={lg}
        color={style.color}
        color0={this.state.themeData.colors[0]}
        link_to_pages={this.state.link_to_pages}
        link_to_sections={this.state.link_to_sections}
        matches={this.state.matches}
        fixed={this.state.fixed_top}
        onScroll={this.handleScroll}
      />
    );

    const navCStyle = this.state.isScrolling ? { paddingTop: '4px', paddingBottom: '4px' } : undefined;

    return (
      <div className={`${navStyle} ${styles[navStyle]}`} id="NavigationBarAnchor">
        <div className={styles.navContainer} style={navCStyle}>
          <div style={style} className={styles.background} />
          <div className={styles[navWrapper]}>
            <div className={`${this.state.full ? '' : 'container'} ${styles[navbar]} ${noLinks !== undefined ? styles[noLinks] : ''}`}>
              {
                (
                  this.state.layout !== 'divided' ||
                  this.state.links.length === 0 ||
                  (
                    this.state.layout === 'divided' &&
                    (this.state.links.length > this.state.burgerBreak || !this.state.matches)
                  )
                ) &&
                (
                  <div>
                    { lg }
                  </div>
                )
              }
              { nav }
            </div>
          </div>
        </div>
      </div>
    );

  }

}

NavigationBar.propTypes = {
  site: PropTypes.shape({}),
  update: PropTypes.func,
};

export default CSSModules(NavigationBar, styles, { allowMultiple: true });
