import React from 'react';
import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import HTMLParser from 'html-react-parser';

import styles from './styles.module.css';

import OSMap from './OSMap';
import GMAP from './GMAP';

class MapLayout extends React.PureComponent {

  constructor(props) {

    super(props);

    this.state = {
      section: props.section,
      layout: props.section.data[props.section.data.length - 1].layout,
      zoomLevel: props.section.data[2].zoomLevel,
      mapProvider: props.mapProvider || 'OSM',
    };

  }

  render() {

    // Let's be sure this works :D

    let bgStyle = {
      backgroundColor: '#FFFFFF',
      opacity: 1,
    };

    if (this.props && this.props.section && this.props.section.data
      && this.props.section.data.length > 0
      && this.props.section.data[this.props.section.data.length - 1].styles
      && this.props.section.data[this.props.section.data.length - 1].styles.backgroundColor
      && this.props.section.data[this.props.section.data.length - 1].styles.backgroundColor.solid
      && this.props.section.data[this.props.section.data.length - 1].styles.backgroundColor.opacity)
    {

      bgStyle = {
        backgroundColor: this.props.section.data[this.props.section.data.length - 1].styles.backgroundColor.solid,
        opacity: this.props.section.data[this.props.section.data.length - 1].styles.backgroundColor.opacity,
      };

    }

    let { mapStyle } = this.props;
    if (
      this.props.mapStyle === 'light' || this.props.mapStyle === 'undefined' || !this.props.mapStyle
    ) {

      mapStyle = 'tinted';

    }

    const themeStyle = styles[`${mapStyle}${this.props.themeData.name.toLowerCase()}`];

    const cols = (this.state.layout !== 'center' && this.state.layout !== 'full' && this.state.layout !== 'fullFloaty') ? 'col-12 col-md-6' : 'col-12';

    let mapElement;

    if (this.state.mapProvider === 'OSM') {

      mapElement = (<OSMap
        zoom={this.state.zoomLevel || 14}
        popupText={this.state.section.data[2].popupText}
        layout={this.state.layout}
        lat={this.state.section.data[2].lat || 0}
        lng={this.state.section.data[2].lng || 0}
        id={this.state.section._id}
      />);

    } else {

      mapElement =(<GMAP
        lat={this.state.section.data[2].lat || 0}
        lng={this.state.section.data[2].lng || 0}
        zoom={this.state.zoomLevel || 14}
      />);

    }

    let innerWrapper = styles.mapInnerWrapper;
    let { mapFrameWrapper } = styles;

    if (this.state.mapProvider === 'OSM') {

      if (this.state.layout === 'fullFloaty') {

        if (this.props.mobileView) {

          innerWrapper = styles.mapInnerWrapperOSMFloatyMobile;
          mapFrameWrapper = `${styles.mapFrameWrapperOSM} ${styles.positionRelative}`;

        } else {

          innerWrapper = styles.mapInnerWrapperOSMFloaty;
          mapFrameWrapper = `${styles.mapFrameWrapperOSM} ${styles.positionAbsolute} ${styles.height100}`;

        }

      } else {

        innerWrapper = styles.mapInnerWrapperOSM;
        mapFrameWrapper = `${styles.mapFrameWrapperOSM} ${styles.minHeight100}`;

      }

    }

    let layoutAlign = '';

    if (this.state.layout === 'right') {

      layoutAlign = `${styles.mapLayoutRight}`;

    }

    return (
      <div className={`
        ${(this.state.layout === 'full' || this.state.layout === 'fullFloaty') ? '' : 'container'}
        ${(this.state.layout === 'full' || this.state.layout === 'fullFloaty') ? styles.noPadding : ''}`}
      >
        <div className={`row no-gutters ${layoutAlign}`}>
          {
            (
              (
                (this.state.section.data[0].active || this.state.section.data[1].active) &&
                (this.state.layout === 'center' || this.state.layout === 'full')
              )
              || (this.state.layout !== 'center' && this.state.layout !== 'full')
            )
            && (
              <div className={`${cols} ${innerWrapper}`} style={bgStyle}>
                <div>
                  <div className={styles.mapText}>
                    {
                      this.state.section.data[0].active &&
                      (
                        HTMLParser(`<h2>${this.state.section.data[0].data}</h2>`)
                      )
                    }

                  </div>
                  <div className={styles.mapText}>
                    {
                      this.state.section.data[1].active &&
                      (
                        HTMLParser(`<span>${this.state.section.data[1].data}</span>`)
                      )
                    }
                  </div>
                </div>
              </div>
            )
          }
          <div className={`${cols} ${mapFrameWrapper} ${themeStyle}`}>
            <div className={`${styles.mapStyle} ${styles.width100} ${styles.positionAbsolute} ${styles.height100}`}>
              {mapElement}
            </div>
          </div>
        </div>
      </div>
    );

  }

}

MapLayout.propTypes = {
  section: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape),
  }),
};

export default CSSModules(MapLayout, styles, { allowMultiple: true });
